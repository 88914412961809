<script lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment'
import formFieldsInit from '../../utils/formFieldsInit.vue'
import FormLabel from './label.vue'

export default {
    name: 'DatetimeField',
    components: { FormLabel, VueDatePicker },
    extends: formFieldsInit,
    props: {
        enableTimePicker: { type: Boolean, required: false },
    },
    emits: ['updateData'],
    data() {
        return {
            moment,
            showInfoMessage: false,
        }
    },
    mounted() {
        moment.locale('fr')
    },
    methods: {
        getDateFormat(date) {
            return date ? this.enableTimePicker ? moment(date).utc(1).format('YYYY-MM-DD HH:mm') : moment(date).utc(1).format('YYYY-MM-DD') : null
        },
        format(date) {
            return this.enableTimePicker ? this.$formatDateTime(date) : this.$formatDate(date)
        },
    },
}
</script>

<template>
    <FormLabel
        v-if="(data || injectedData || formService?.data) && (value !== undefined || rule)"
        :class="!getErrors() && 'mb-4'"
    >
        <VueDatePicker
            v-if="rule"
            :id="customId ?? dataKey"
            :name="customId ?? dataKey"
            :format="format"
            :model-value="value"
            class="custom-input"

            :min-date="getMin()"
            :max-date="getMax()"

            position="center"
            locale="fr"
            cancel-text="Annuler"
            select-text="Valider"
            :action-row="{ showNow: true }"
            now-button-label="Aujourd'hui"
            :enable-time-picker="enableTimePicker"
            :disabled="disabled"
            :aria-label="dataKey"

            auto-apply

            time-picker-inline
            hide-input-icon
            @update:model-value="$emit('updateData', getDateFormat($event)); updateData($event)"
            @blur="validation && validation.$touch() || injectedValidation?.$touch()"
            @open="showInfoMessage = true"
            @closed="showInfoMessage = false"
        >
            <template #trigger>
                <v-text-field
                    :value="format(value)"
                    clearable
                    class="custom-input"
                    :error="!!getErrors()?.length"
                    :disabled="disabled"
                    density="compact"
                    variant="outlined"
                    persistent-hint
                    :hint="getInfo()"
                    :error-messages="getErrors()"
                    @click:clear="$emit('updateData', null)"
                />
            </template>
        </VueDatePicker>
        <div v-else>
            <div v-if="value">
                {{ format(value) }}
            </div>
            <div v-else>
                <small class="font-italic text-disabled">-</small>
            </div>
        </div>
    </FormLabel>
</template>
